
import React from "react";
import countryList from "./country-list";
import Error from "./Error";
import { isUserLoggedIn } from "~/utils/functions";
import { isEmpty } from "lodash";
import CreateAccount from "./CreateAccount";

const Billing = ({ input, handleOnChange }) => {

	const auth = isUserLoggedIn();

  return (
    <React.Fragment>
      {/*Name*/}

      <div className="billing-info space-y-5">
        <div className="form-row space-y-5">
          <div className="form-group space-y-1">
            <label htmlFor="first-name" className="font-medium t-17">
              First Name
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.firstName}
              type="text"
              name="firstName"
              className="form-control form-input woo-next-checkout-input"
              id="first-name"
            />
            <Error errors={input.errors} fieldName={"firstName"} />
          </div>
          <div className="form-group space-y-1">
            <label htmlFor="last-name" className="font-medium t-17">
              Last Name
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.lastName}
              type="text"
              name="lastName"
              className="form-control form-input woo-next-checkout-input"
              id="last-name"
            />
            <Error errors={input.errors} fieldName={"lastName"} />
          </div>
        </div>

        {/* Company Name */}
        <div className="form-group space-y-1">
          <label htmlFor="company-name" className="font-medium t-17">Company Name</label>
          <input
            onChange={handleOnChange}
            value={input.company}
            type="text"
            name="company"
            className="form-control form-input woo-next-checkout-input"
            id="company-name"
          />
          <Error errors={input.errors} fieldName={"company"} />
        </div>
        {/* Country */}
        <div className="form-group space-y-1">
          <label htmlFor="country-select" className="font-medium t-17">
            Country
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          {/* eslint-disable */}
          <select
            onChange={handleOnChange}
            value={input.country}
            name="country"
            className="form-control country-select form-input woo-next-checkout-input"
            id="country-select"
          >
            <option value="">Select a country...</option>
            {countryList.length &&
              countryList.map((country, index) => (
                <option key={`${country}-${index}`} value={country.countryCode}>
                  {country.countryName}
                </option>
              ))}
          </select>
          <Error errors={input.errors} fieldName={"country"} />
        </div>
        {/* Street Address */}
        <div className="form-group space-y-5 ">
          <div className="space-y-1">
            <label htmlFor="street-address" className="font-medium t-17">
              Street Address
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              type="text"
              onChange={handleOnChange}
              value={input.address1}
              name="address1"
              placeholder="House number and street name"
              className="form-control form-input woo-next-checkout-input"
              id="street-address"
            />
            <Error errors={input.errors} fieldName={"address1"} />
          </div>
          <input
            type="text"
            onChange={handleOnChange}
            value={input.address2}
            name="address2"
            placeholder="Apartment, suite, unit etc.(optional)"
            className="form-control form-input woo-next-checkout-input"
            id="address-2"
          />
        </div>
        {/* Town/City */}
        <div className="form-group space-y-1">
          <label htmlFor="city" className="font-medium t-17">
            Town/City
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            onChange={handleOnChange}
            value={input.city}
            type="text"
            name="city"
            className="form-control form-input woo-next-checkout-input"
            id="city"
          />
          <Error errors={input.errors} fieldName={"city"} />
        </div>
        {/* County */}
        <div className="form-group space-y-1">
          <label htmlFor="state" className="font-medium t-17">
            State/County
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            onChange={handleOnChange}
            value={input.state}
            type="text"
            name="state"
            className="form-control form-input woo-next-checkout-input"
            id="state"
          />
          <Error errors={input.errors} fieldName={"state"} />
        </div>
        {/* Post Code */}
        <div className="form-group space-y-1">
          <label htmlFor="post-code" className="font-medium t-17">
            Postcode
            <abbr className="required" title="required">
              *
            </abbr>
          </label>
          <input
            onChange={handleOnChange}
            value={input.postcode}
            type="text"
            name="postcode"
            className="form-control form-input woo-next-checkout-input"
            id="post-code"
          />
          <Error errors={input.errors} fieldName={"postcode"} />
        </div>
        {/*Phone & Email*/}
        <div className="form-row space-y-5">
          <div className="form-group space-y-1">
            <label htmlFor="phone" className="font-medium t-17">
              Phone
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.phone}
              type="text"
              name="phone"
              className="form-control form-input woo-next-checkout-input"
              id="phone"
            />
            <Error errors={input.errors} fieldName={"phone"} />
          </div>
          <div className="form-group space-y-1">
            <label htmlFor="email" className="font-medium t-17">
              Email
              <abbr className="required" title="required">
                *
              </abbr>
            </label>
            <input
              onChange={handleOnChange}
              value={input.email}
              type="email"
              name="email"
              className="form-control form-input woo-next-checkout-input"
              id="email"
            />
            <Error errors={input.errors} fieldName={"email"} />
          </div>
        </div>
      </div>

	    { isEmpty( auth ) ? <CreateAccount handleOnChange={ handleOnChange } input={ input }/> : null }

      {/* <h4 className="mt-4 mb-4">Additional Information</h4>
      <div className="form-group">
      	<label htmlFor="order-notes">Order Notes</label>
      	<textarea onChange={ handleOnChange } defaultValue={ input.customerNote } name="customerNote" className="form-control woo-next-checkout-textarea" id="order-notes" rows="4"/>
      	<Error errors={ input.errors } fieldName={ 'customerNote' }/>
      </div> */}
    </React.Fragment>
  );
};

export default Billing;
