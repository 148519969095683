import { graphql, Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Layout from "~/templates/Layout";
import CheckoutForm from "~/components/shop/checkout/CheckoutForm";
import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function checkoutPage({data: { wpPage, wpPost, wp },}: {data: { wpPage: { page } };}) {

  return (
    <Layout wp={wp} headerColor="light-blue">
      <Seo post={wpPage} title={wpPage?.title} />
      <CheckoutForm />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Checkout($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
    }
    wp {
      ...GeneratedWp
    }
  }
`;
