import React from 'react';
import CheckoutCartItem from "./CheckoutCartItem";

const Index = ( { cart } ) => {

	return (
		<>
			{ cart ? (
				<div className="bg-white border border-navy border-opacity-20 rounded-[10px] p-5 md:p-10">
					{/*Product Listing*/}
					<table className="table table-hover w-full">
						<thead>
						{ /* eslint-disable */ }
						<tr className="woo-next-cart-head-container">
							<th className="woo-next-cart-heading-el"/>
							<th className="woo-next-cart-heading-el text-right font-medium">Product</th>
							<th className="woo-next-cart-heading-el text-right font-medium">Total</th>
						</tr>
						</thead>
						<tbody>
						{ cart.products.length && (
							cart.products.map( item => (
								<CheckoutCartItem key={ item.productId } item={ item } />
							) )
						) }
						{/*Total*/}
						<tr className="text-right">
							<td className=""/>
							<td className="woo-next-checkout-total font-semibold">Subtotal</td>
							<td className="woo-next-checkout-total font-semibold">{ cart.totalProductsPrice }</td>
						</tr>
						<tr className="text-right">
							<td className=""/>
							<td className="woo-next-checkout-total font-semibold">Total</td>
							<td className="woo-next-checkout-total font-semibold">{ cart.totalProductsPrice }</td>
						</tr>
						</tbody>
					</table>
				</div>
			) : '' }
		</>
	)
};

export default Index;
