import React from 'react';
const CheckoutCartItem = ( { item } ) => {

	return (
		<tr className="woo-next-cart-item" key={ item.productId }>
			<td className="woo-next-cart-element">
				<div className="w-32">
					<img width="128" src={ item.image.sourceUrl } srcSet={ item.image.srcSet } alt={item.image.title}/>
				</div>
			</td>
			<td className="woo-next-cart-element text-right">{ item.name }</td>
			<td className="woo-next-cart-element text-right">{ item.totalPrice }</td>
		</tr>
	)
};

export default CheckoutCartItem;
