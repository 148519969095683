import React, { useState } from 'react';
import Error from "./Error";

const CreateAccount = ( { handleOnChange, input } ) => {

	const [formVisibility, setFormVisibility] = useState( false );

	return (
		<div className="form-check space-y-2">
			<label className="form-check-label flex items-center space-x-2">
				<input
					onChange={ handleOnChange }
					onClick={ () => setFormVisibility( ! formVisibility ) }
					className={`cursor-pointer rounded-[2px] w-[18px] h-[18px] border border-[#ABABAB] group-hover:border-navy focus-within:border-navy bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out `}
					name="createAccount"
					type="checkbox"
				/>
				<div className="font-medium ">
					Create an account?
				</div>
			</label>

			{
				formVisibility ? (
					// Create account fields
					<div className="form-row mt-2 space-y-5">

						{/* Username */ }
						<div className="form-group col-md-6">
							<label htmlFor="username" className="screenreader-text">>
								Username
								<abbr className="required" title="required">
									*
								</abbr>
							</label>
							<input
								type="text"
								className="form-control form-input woo-next-checkout-input"
								id="username"
								name="username"
								placeholder="Enter username"
								value={ input.username }
								onChange={ handleOnChange }
							/>
							<Error errors={input.errors} fieldName={"username"} />
						</div>

						{/* Password */ }
						<div className="form-group col-md-6">
							<label htmlFor="password" className="screenreader-text">
								Password
								<abbr className="required" title="required">
									*
								</abbr>
							</label>
							<input
								type="password"
								className="form-control form-input woo-next-checkout-input"
								id="password"
								name="password"
								placeholder="Enter password"
								value={ input.password }
								onChange={ handleOnChange }
							/>
							<Error errors={input.errors} fieldName={"password"} />
						</div>
					</div>
				) : null
			}
		</div>
	)
};

export default CreateAccount;
