import React from 'react';
import { LinkInner } from '~/components/elements/Link';

const OrderSuccess = ( props ) => {

	const { response } = props;

	if ( ! response ) {
		return null;
	}

	const responseData = response.checkout;

	// window.location.href = responseData.redirect;

	return (
		<div className="container py-40 pt-20">
				<div className="bg-white rounded-[10px] max-w-[680px] mx-auto card-shadow p-5 py-10 lg:p-16 space-y-5 text-center">
					{ 'success' === responseData.result ? (
						<>
							<h2 className="t-45">Your order is complete!</h2>
							<p className="t-black-65 t-21-normal">We'll be in touch to you let you know more about your order.</p>
							<div className="t-18 flex items-center justify-center"><div className="font-semibold">Order no</div> : { responseData.order.orderKey }</div>
							{/* <div className="t-18 flex items-center justify-center"><div className="font-semibold">Status</div> : { responseData.order.status }</div> */}
							<LinkInner link={{ url: '/', title: 'Back home' }} type="button" />
						</>
					) : (
						<>
							<h2 className="t-45">Sorry!</h2>
							<div className="text-error t-21-normal">It looks like something went wrong with your order.</div>
							<LinkInner link={{ url: '/contact', title: 'Get in touch' }} type="button" />
						</>
					)}
				</div>
			
		</div>
	)
};

export default OrderSuccess;
